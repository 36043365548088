import AOS from 'aos';

(function() {
// Selection of HTML objects
const burger = document.querySelector('.burger');
const nav = document.querySelector('.nav');
const body = document.querySelector('.body');
const topNav = document.querySelector('.top');

$('.mod_article img, .case').attr({
    'data-aos':'zoom-in',
    'data-aos-anchor-placement':'top-bottom',
    // 'data-aos-delay': '250',
    'data-aos-duration':'1000'

});


// Defining a function
function toggleNav() {
    body.classList.toggle('is-active');
    topNav.classList.toggle('active');
    burger.classList.toggle('is-active');
    nav.classList.toggle('nav-active');

}
// Calling the function after click event occurs
burger.addEventListener('click', function(evt) {
    evt.preventDefault();
    toggleNav();
});

function resize(){
    if(innerWidth >991){
        body.classList.remove('is-active');
        topNav.classList.remove('active');
        burger.classList.remove('is-active');
        nav.classList.remove('nav-active');
    }
}
setTimeout(()=>{
    AOS.init({disable: 'mobile'});
},10);

resize();
window.addEventListener('resize', resize);
function playVisibleVideos() {
    document.querySelectorAll("video").forEach(video => elementIsVisible(video) ? video.play() : video.pause());
}

function elementIsVisible(el) {
    let rect = el.getBoundingClientRect();
    return (rect.bottom >= 0 && rect.right >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight) && rect.left <= (window.innerWidth || document.documentElement.clientWidth));
}

let playVisibleVideosTimeout;
window.addEventListener("scroll", () => {
    clearTimeout(playVisibleVideosTimeout);
    playVisibleVideosTimeout = setTimeout(playVisibleVideos, 100);
});

window.addEventListener("resize", playVisibleVideos);
window.addEventListener("DOMContentLoaded", playVisibleVideos);
})();